<template>
	<v-app class="auth-bg">
		<v-main>
			<v-container
					fill-height
					fluid
			>
				<v-layout
						align-center
						justify-center
				>
					<v-flex
							lg4
							md6
							sm10
							xs12
					>
						<v-card
								class="elevation-12"
								rounded="xl"
						>
							<div class="py-4 px-2">
								<v-card-title class="justify-center">
									<logo-component
											:height="48"
											:width="120"
											color="#E98700"
									/>
								</v-card-title>
								
								<div class="mb-4 px-4">
									<slot />
								</div>
							</div>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>

import LogoComponent from '@/components/common/LogoComponent.vue';

export default {
	components: {LogoComponent}
};
</script>

<style scoped>
.auth-bg {
	animation: gradient 15s ease infinite;
	background-size: 400% 400%;
	background: linear-gradient(315deg, rgba(101, 0, 94, 1) 3%, rgba(60, 132, 206, 1) 38%, rgba(48, 238, 226, 1) 68%, rgba(255, 25, 25, 1) 98%) fixed;
}
</style>
